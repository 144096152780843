.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #001529;
  padding: 10px 0;
}

.logo {
  padding: 0 20px;
  /* font-size: 2.4em; */
}

.logo img {
  height: 45px;
}

.login-form-holder {
  padding: 10px 30px;
  min-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  border: thin solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.login-form {
  padding: 40px;
  margin: 20px;
}

.login-form-forgot {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  /*border is optional*/
  /* border-bottom:1px solid #444;  */
  cursor: pointer;
}

.login-form-forgot:hover {
  cursor: pointer;
  color: blue;
}

.login-form-forgot-holder {
  width: 100%;
  text-align: right;
}

.login-form .anticon {
  color: rgba(0, 0, 0, 0.25);
}

.login-avatar {
  margin-top: 40px;
  text-align: center;
}

.login-avatar .ant-avatar-icon {
  background-color: #1890ff !important;
}

.dashboard-wrapper {
  display: flex;
  height: 100%;
  margin: 10px;
}
.ant-statistic-title {
  font-size: 1.2em;
  font-weight: 700;
}

.stat-tag {
  font-size: larger;
  font-weight: 700;
  text-align: right;
}

.dashboard-main-card {
  height: 100vh;
}

.ant-skeleton-content {
  font-size: smaller;
}

.stat-col {
  margin-top: 15px;
}

.register-form-holder {
  padding: 5px 30px;
  min-width: 500px;
  height: 740px;
  border-radius: 4px;
  background-color: #fff;
  border: thin solid #ccc;
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.register-form {
  padding: 40px;
  margin: 20px;
}

.register-form .anticon {
  color: rgba(0, 0, 0, 0.25);
}

.register-avatar {
  margin-top: 40px;
  text-align: center;
}

.register-avatar .ant-avatar-icon {
  background-color: #1890ff !important;
}

.register-form .ant-row {
  height: 27px;
  /* background-color: yellow; */
}

.register-form .ant-form-item-with-help {
  height: 40px !important;
}

.register-form .split {
  display: inline-block;
  width: 50%;
  padding: 3px;
}

.reset-password-form-holder {
  padding: 10px 30px;
  min-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  border: thin solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.reset-password-form {
  padding: 40px;
  margin: 20px;
}

.reset-password-form-forgot {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  /*border is optional*/
  /* border-bottom:1px solid #444;  */
  cursor: pointer;
}

.reset-password-form-forgot:hover {
  cursor: pointer;
  color: blue;
}

.reset-password-form-forgot-holder {
  width: 100%;
  text-align: right;
}

.reset-password-form .anticon {
  color: rgba(0, 0, 0, 0.25);
}

.reset-password-avatar {
  margin-top: 40px;
  text-align: center;
}

.reset-password-avatar .ant-avatar-icon {
  background-color: #1890ff !important;
}

.reset-email-form-holder {
  padding: 10px 30px;
  min-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  border: thin solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.reset-email-form {
  padding: 40px;
  margin: 20px;
}

.reset-email-form-forgot {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  /*border is optional*/
  /* border-bottom:1px solid #444;  */
  cursor: pointer;
}

.reset-email-form-forgot:hover {
  cursor: pointer;
  color: blue;
}

.reset-email-form-forgot-holder {
  width: 100%;
  text-align: right;
}

.reset-email-form .anticon {
  color: rgba(0, 0, 0, 0.25);
}

.reset-email-avatar {
  margin-top: 40px;
  text-align: center;
}

.reset-email-avatar .ant-avatar-icon {
  background-color: #1890ff !important;
}

.logout-holder {
  padding: 10px 30px;
  min-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  border: thin solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.logout-header {
  margin: 20px;
}

.ant-tabs-content .toolbar {
  padding: 10px !important;
  border: thin solid #efefef;
}





.customer-details-card {
  min-height: 80vh;
  -webkit-animation: fadein 1s;
          animation: fadein 1s;
}

.customer-details-card .ant-tabs-tabpane-active {
  margin-top: 10px;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.split {
  width: 50%;
  display: inline-block;
}

.toolbar {
  padding: 30px;
}


.player-container {
  max-width: 768px;
  width: 100%;
  margin: 10px auto 0;
}

.player-container button {
  margin: 30px 0 10px;
}

.recorder-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  height: auto;
  position: relative;
  z-index: 1;
}

.recorder-filter-container {
  padding: 30px 30px 30px 25px;
  background: #f7f7f7;
  border: 1px solid #e8e8e8;
  margin-right: 3px;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}

.recorder-filter-container h3 {
  margin-bottom: 20px;
}

.recorder-filter-form .ant-form-item {
  margin-bottom: 15px;
}

.recorder-list-container {
  width: 100%;
  height: 100%;
  align-self: flex-start;
  margin-bottom: auto;
}

.recorder-list-container .ant-body {
  height: 100%;
}

.form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.recorder-details-card {
  text-align: center;
}

.customer-details-card {
    padding: 10px 40px;
    -webkit-animation: fadein 1s;
            animation: fadein 1s;
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.split {
    width: 50%;
    display: inline-block;
}

.ant-btn-group {
    margin-right: 10px;
}

.layout-footer {
  background: #001529;
}

.center-wrapper {
  padding: 10px 60px;
  min-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  border: thin solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.login-avatar {
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
}

/* .login-avatar .ant-avatar-icon {
  background-color: #1890ff !important;
} */

body {
  background-color: #193141 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header-title {
  text-align: center;
}

.header-title h1 {
  color: #fff;
  font-size: larger;
  font-weight: bolder;
}

.layout-footer {
  text-align: center;
}

.layout-main {
  height: 100vh;
}

.layout-child {
  height: 100%;
}

.close-card {
  font-size: 1.6em;
}

.full-height {
  height: 100%;
}

/* .ant-card-extra button {
     background:none!important;
     color:inherit;
     border:none; 
     padding:0!important;
     font: inherit;
     cursor: pointer;
     color: #1890ff;
     font-size: 1.1em;
}

.ant-input-group-addon {
     background:none!important;
     color:inherit;
     border:none; 
     padding:0!important;
     font: inherit;
     cursor: pointer;
     color: #1890ff;
     font-size: 1.1em;
} */

.ant-pagination {
  margin: 20px;
  text-align: right;
}

.toolbar .buttons {
  text-align: right;
}

body {
  overflow-x: hidden;
}

