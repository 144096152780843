.customer-details-card {
  min-height: 80vh;
  animation: fadein 1s;
}

.customer-details-card .ant-tabs-tabpane-active {
  margin-top: 10px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.split {
  width: 50%;
  display: inline-block;
}

.toolbar {
  padding: 30px;
}
