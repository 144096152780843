@import '~antd/dist/antd.css';

body {
  background-color: #193141 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header-title {
  text-align: center;
}

.header-title h1 {
  color: #fff;
  font-size: larger;
  font-weight: bolder;
}

.layout-footer {
  text-align: center;
}

.layout-main {
  height: 100vh;
}

.layout-child {
  height: 100%;
}

.close-card {
  font-size: 1.6em;
}

.full-height {
  height: 100%;
}

/* .ant-card-extra button {
     background:none!important;
     color:inherit;
     border:none; 
     padding:0!important;
     font: inherit;
     cursor: pointer;
     color: #1890ff;
     font-size: 1.1em;
}

.ant-input-group-addon {
     background:none!important;
     color:inherit;
     border:none; 
     padding:0!important;
     font: inherit;
     cursor: pointer;
     color: #1890ff;
     font-size: 1.1em;
} */

.ant-pagination {
  margin: 20px;
  text-align: right;
}

.toolbar .buttons {
  text-align: right;
}
