.register-form-holder {
  padding: 5px 30px;
  min-width: 500px;
  height: 740px;
  border-radius: 4px;
  background-color: #fff;
  border: thin solid #ccc;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.register-form {
  padding: 40px;
  margin: 20px;
}

.register-form .anticon {
  color: rgba(0, 0, 0, 0.25);
}

.register-avatar {
  margin-top: 40px;
  text-align: center;
}

.register-avatar .ant-avatar-icon {
  background-color: #1890ff !important;
}

.register-form .ant-row {
  height: 27px;
  /* background-color: yellow; */
}

.register-form .ant-form-item-with-help {
  height: 40px !important;
}

.register-form .split {
  display: inline-block;
  width: 50%;
  padding: 3px;
}
