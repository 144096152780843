.login-form-holder {
  padding: 10px 30px;
  min-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  border: thin solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-form {
  padding: 40px;
  margin: 20px;
}

.login-form-forgot {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  /*border is optional*/
  /* border-bottom:1px solid #444;  */
  cursor: pointer;
}

.login-form-forgot:hover {
  cursor: pointer;
  color: blue;
}

.login-form-forgot-holder {
  width: 100%;
  text-align: right;
}

.login-form .anticon {
  color: rgba(0, 0, 0, 0.25);
}

.login-avatar {
  margin-top: 40px;
  text-align: center;
}

.login-avatar .ant-avatar-icon {
  background-color: #1890ff !important;
}
