.logout-holder {
  padding: 10px 30px;
  min-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  border: thin solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logout-header {
  margin: 20px;
}
