.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #001529;
  padding: 10px 0;
}

.logo {
  padding: 0 20px;
  /* font-size: 2.4em; */
}

.logo img {
  height: 45px;
}
