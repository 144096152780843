.recorder-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  height: auto;
  position: relative;
  z-index: 1;
}

.recorder-filter-container {
  padding: 30px 30px 30px 25px;
  background: #f7f7f7;
  border: 1px solid #e8e8e8;
  margin-right: 3px;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}

.recorder-filter-container h3 {
  margin-bottom: 20px;
}

.recorder-filter-form .ant-form-item {
  margin-bottom: 15px;
}

.recorder-list-container {
  width: 100%;
  height: 100%;
  align-self: flex-start;
  margin-bottom: auto;
}

.recorder-list-container .ant-body {
  height: 100%;
}

.form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.recorder-details-card {
  text-align: center;
}

.customer-details-card {
    padding: 10px 40px;
    animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.split {
    width: 50%;
    display: inline-block;
}

.ant-btn-group {
    margin-right: 10px;
}
