.dashboard-wrapper {
  display: flex;
  height: 100%;
  margin: 10px;
}
.ant-statistic-title {
  font-size: 1.2em;
  font-weight: 700;
}

.stat-tag {
  font-size: larger;
  font-weight: 700;
  text-align: right;
}

.dashboard-main-card {
  height: 100vh;
}

.ant-skeleton-content {
  font-size: smaller;
}

.stat-col {
  margin-top: 15px;
}
