.center-wrapper {
  padding: 10px 60px;
  min-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  border: thin solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-avatar {
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
}

/* .login-avatar .ant-avatar-icon {
  background-color: #1890ff !important;
} */
